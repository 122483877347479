<template>
  <div class="d-flex" id="wrapper" :class="toggleClass">
    <!-- Sidebar -->
    <div v-if="showMenu" class="border-right border-lighter slide-menu-md" id="sidebar-wrapper">
      <div class="sidebar-heading border-lighter bg-dark" style="min-height: 60px">
        <router-link :to="{ name: 'home' }" class="logo-link logo">
          <img :src="baseUrl('/images/reunion-marketing-logo.svg')"
               style="max-width: 100%; width: 185px" alt="Reunion Marketing">
        </router-link>
        <div class="nav-item close-menu" @click.prevent="toggleWrapper">
          <a class="close-link" href="#"><i class="fas fa-angle-double-left"></i>&nbsp;Close Menu</a>
        </div>
      </div>
      <button v-if="showMenu" class="sidebar-title btn d-flex align-items-center justify-content-between w-100" id="menu-toggle" @click.prevent="toggleWrapper">
        <h5 v-if="activeSidebar === 'client-services-sidebar'" class="mb-0">Client Services</h5>
        <h5 v-if="activeSidebar === 'administration-sidebar'" class="mb-0">Administration</h5>
        <h5 v-if="activeSidebar === 'data-management-sidebar'" class="mb-0">Data Management</h5>
        <i v-if="toggleClass !== 'toggled'" class="fas fa-outdent" style="font-size: 1.2rem;"></i>
        <i v-else class="fas fa-indent" style="font-size: 1.2rem;"></i>
      </button>
      <div v-if="sidebarMenu" class="main-menu navbar navbar-expand-nvsidebar-content m-0 p-0" style="min-height: 60px">
        <div id="panelNavDropdown" class="w-100">
          <ul class="navbar-nav flex-column">
            <dynamic-sidebar :menuId="sidebarMenu.id" />
          </ul>
        </div>
      </div>
    </div>
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">

      <div
        class="navbar-wrapper main-menu navbar navbar-expand-md navbar-dark bg-dark text-light border-bottom border-lighter p-0 m-0">

        <div v-if="!showMenu" class="mr-5" style="min-height: 60px;">
          <div class="logo-link logo">
            <img :src="baseUrl('/images/reunion-marketing-logo.svg')"
                 style="width: 180px" alt="Reunion Marketing">
          </div>
        </div>

        <div v-if="showMenu" class="navbar-heading">
          <router-link :to="{ name: 'home' }" class="logo-link logo">
            <img :src="baseUrl('/images/reunion-marketing-logo.svg')"
                 style="width: 180px" alt="Reunion Marketing">
          </router-link>
        </div>

        <button v-if="showMenu" class="navbar-toggler collapsed btn btn-dark-clear" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
                aria-expanded="false" aria-label="Toggle Navigation">
          <i class="fas fa-bars"></i>
        </button>

        <div class="navbar navbar-dark d-flex p-0 d-sm-none d-md-block">
          <ul class="navbar-nav my-1 me-auto">
            <li v-if="!showMenu && currentRouteName !== 'onboarding-progress-board' && !isSubaruCentralRegion" class="nav-item">
              <span class="nav-text top-nav-link d-flex flex-row flex-items-center has-m-4 has-mt-6">
                 <router-link
                   :to="{ name: 'onboarding-progress-board' }"
                   class="text-decoration-none link-light">
                   Onboarding Dashboard
                 </router-link>
              </span>
            </li>
            <li v-if="!showMenu && currentRouteName !== 'client-contents' && isSubaruCentralRegion" class="nav-item">
              <span class="nav-text top-nav-link d-flex flex-row flex-items-center has-m-4 has-mt-6">
                 <router-link
                   :to="{ name: 'client-contents' }"
                   class="text-decoration-none link-light">
                   Client Content Overview
                 </router-link>
              </span>
            </li>
            <li v-if="(isClientUser || isPartnerUser) && !isSubaruCentralRegion" class="nav-item">
              <span class="nav-text top-nav-link d-flex flex-row flex-items-center has-m-4 has-mt-6">
                <loading-icon :loading="isLoading"><i class="fas"></i></loading-icon>
                <router-link v-if="seoReportingDashboardMenu"
                  :to="{ name: 'seo-reporting-dashboard' }"
                  class="text-decoration-none link-light">
                   SEO Reporting Dashboard
                 </router-link>
              </span>
            </li>
            <li v-if="showMenu" class="nav-item">
              <span class="nav-text top-nav-link d-flex flex-row flex-items-center has-m-4 has-my-3"
                    :class="currentRouteName === 'clients.index' ? 'dropdown' : 'dropend'">
                 <router-link
                   :to="{ name: 'clients.index' }"
                   class="text-decoration-none link-light"
                   :aria-expanded="ariaExpanded('clients')">
                   <i class="fas fa-list me-2" style="font-size: 1rem;"></i> Clients List
                 </router-link>
                <router-link
                  :to="{ name: 'partners.list' }"
                  class="ms-4 text-decoration-none link-light"
                  :aria-expanded="ariaExpanded('clients')">
                  <i class="fas fa-list me-2" style="font-size: 1rem;"></i> Partners List
                </router-link>
              </span>
            </li>
          </ul>
        </div>

        <div class="navbar-collapse collapse" id="navbarCollapse">
          <div id="navbarNavDropdown" class="ms-sm-auto">
            <ul class="navbar-nav py-2 mt-lg-0">
              <template v-if="user?.user_type === 'user'">
                <li class="nav-item" @click="$store.dispatch('setActiveSidebar', 'client-services-sidebar')">
                  <button class="btn btn-link nav-text d-flex flex-row text-white text-decoration-none"
                    :class="activeSidebar === 'client-services-sidebar' ? 'active' : ''"
                  >
                    Client Services
                  </button>
                </li>
                <li class="nav-item" @click="$store.dispatch('setActiveSidebar', 'administration-sidebar')">
                  <button class="btn btn-link nav-text d-flex flex-row text-white text-decoration-none"
                    :class="activeSidebar === 'administration-sidebar' ? 'active' : ''"
                  >
                    Administration
                  </button>
                </li>
                <li class="nav-item" @click="$store.dispatch('setActiveSidebar', 'data-management-sidebar')">
                  <button class="btn btn-link nav-text d-flex flex-row text-white text-decoration-none"
                    :class="activeSidebar === 'data-management-sidebar' ? 'active' : ''"
                  >
                    Data Management
                  </button>
                </li>
              </template>
              <!-- <li class="nav-item">
                <span class="nav-text d-flex flex-row">
                  Welcome!&nbsp;{{ firstName(user) }}&nbsp;&nbsp;
                  <inline-avatar
                    v-if="user.id"
                    :user-id="user.id"
                    :user-name="fullName(user)"
                    :avatar="userAvatar"/>
                </span>
              </li> -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle me-4 d-flex align-items-center text-white" href="#" id="navbarDropdown" role="button"
                   data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ fullName(user) }}
                  <inline-avatar
                    v-if="user.id"
                    :user-id="user.id"
                    :user-name="fullName(user)"
                    :avatar="userAvatar"
                    class="ms-2"
                  />
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                  <router-link v-if="clientUser" :to="{ name: 'client-users.profile' }" class="dropdown-item">
                    Profile
                  </router-link>
                  <router-link v-else-if="partnerUser" :to="{ name: 'partner-users.profile' }" class="dropdown-item">
                    Profile
                  </router-link>
                  <router-link v-else :to="{ name: 'users.profile' }" class="dropdown-item">
                    Profile
                  </router-link>
                  <div class="dropdown-divider"></div>
                  <a href="#" @click.prevent="logout" class="dropdown-item">
                    <i class="fas fa-sign-out-alt"></i>&nbsp;Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container-fluid px-0 page-body overflow-hidden">

        <inline-message :message="appSuccessMsg" @update:message="appSuccessMsg = $event" type="success" :dismissible="true" :transient="true"/>
        <inline-message :message="appErrorMsg" @update:message="appErrorMsg = $event" type="danger" :dismissible="true" :transient="true"/>
        <inline-message :message="appWarningMsg" @update:message="appWarningMsg = $event" type="warning" :dismissible="true" :transient="true"/>
        <inline-message :message="appInfoMsg" @update:message="appInfoMsg = $event" type="info" :dismissible="true" :transient="true"/>

        <!-- force shared component to reload data when changing path -->
        <router-view :key="$route.fullPath"></router-view>
      </div>

    </div>
    <!-- /#page-content-wrapper -->

  </div>
  <!-- /#wrapper -->
</template>

<script>
import { mapGetters } from 'vuex'
import InlineAvatar from '../components/Avatars/InlineAvatar'
import DynamicSidebar from './components/DynamicSidebar'

export default {
  name: 'AdminLayout',
  components: {
    InlineAvatar,
    DynamicSidebar
  },
  data () {
    return {
      toggleClass: '',
      // once roles & permissions are enabled, the default activeSidebar will be driven my user role
      initialActiveSidebar: 'client-services-sidebar',
      module: null,
      sidebarMenu: null,
      seoReportingDashboardMenu: false,
      isLoading: true
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/auth/login')
        })
    },
    toggleWrapper () {
      if (this.toggleClass.length > 0) {
        this.toggleClass = ''
      } else {
        this.toggleClass = 'toggled'
      }
    },
    ariaExpanded (module) {
      return module === this.module ? 'true' : 'false'
    },
    getMenus () {
      if (!this.menus || this.menus?.length <= 0) {
        this.$store.dispatch('setMenus')
      } else {
        this.setSidebar()
      }
    },
    seoReportingDashboard () {
      if (this.clientUser) {
        this.$api.get('/client-users/'+this.currentUser.id+'/seo-reporting-dashboard-clients')
          .then(response => {
            this.isLoading = false
            if (response.data.length) {
              this.seoReportingDashboardMenu = true
            }
          }, (error) => {
            this.isLoading = false
            console.log(error)
          })
      } else if (this.partnerUser) {
        this.$api.get('/partner-users/'+this.currentUser.id+'/seo-reporting-dashboard-clients')
          .then(response => {
            this.isLoading = false
            if (response.data.length) {
              this.seoReportingDashboardMenu = true
            }
          }, (error) => {
            this.isLoading = false
            console.log(error)
          })
      } else {
        this.isLoading = false
        this.seoReportingDashboardMenu = false
      }
    },
    setSidebar () {
      if (this.menus && this.menus?.length > 0) {
        this.sidebarMenu = this.menus.find(
          // once roles & permissions are enabled, the default activeSidebar will be driven my user role
          menu => (menu.slug === this.activeSidebar && menu.application_identity.slug === 'dalmatian-portal')
        )
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'appError',
      'appSuccess',
      'appWarning',
      'appInfo',
      'isReunionUser',
      'isClientUser',
      'isPartnerUser',
      'menus',
      'activeSidebar',
      'isSubaruCentralRegion'
    ]),
    currentRouteName () {
      return this.$route.name
    },
    user: function () {
      return this.currentUser
    },
    userAvatar: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.now = Date.now()
      return this.user && this.user.avatar_url ? this.user.avatar_url + '?' + this.now : ''
    },
    appErrorMsg: function () {
      return this.appError
    },
    appSuccessMsg: function () {
      return this.appSuccess
    },
    appWarningMsg: function () {
      return this.appWarning
    },
    appInfoMsg: function () {
      return this.appInfo
    },
    showMenu: function () {
      return this.isReunionUser
    },
    clientUser: function () {
      return this.isClientUser
    },
    partnerUser: function () {
      return this.isPartnerUser
    }
  },
  mounted () {
    this.seoReportingDashboard()
    this.module = this.$route.meta.module
    this.type = this.$route.params.type
    this.getMenus()

    this.$store.dispatch('setActiveSidebar', (this.activeSidebar || this.initialActiveSidebar))
  },
  watch: {
    $route () {
      this.module = this.$route.meta.module
    },
    activeSidebar () {
      this.setSidebar()
    },
    menus () {
      this.setSidebar()
    }
  }
}
</script>

<style lang="scss">
.top-nav-link a:hover  {color: $primary;}

.page-body {
  height: calc(100% - 60px);
}
</style>
